import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

const Loading = () => {
  return (
    <Center>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="cyan.400" size="lg" />
    </Center>
  )
}

export default Loading
