import { Heading, HStack, LinkBox, LinkOverlay, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { Link as ReachLink } from 'react-router-dom'
import { TeamType } from '../types/team'
import { GetDisplayDatetime } from '../utils/datetime'

type Props = {
  teams: TeamType[]
}

const TeamList: React.FC<Props> = ({ teams }) => {
  return (
    <List>
      {teams.map((team: TeamType) => (
        <ListItem key={team.id} borderBottomWidth="1px" _first={{ borderTopWidth: '1px' }}>
          <LinkBox p={4}>
            <HStack>
              <Heading as="h3" size="xs">
                <LinkOverlay as={ReachLink} to={'/teams/' + team.id}>
                  {team.name}
                </LinkOverlay>
              </Heading>
              <Text fontSize="xs">{GetDisplayDatetime(team.created_at)}</Text>
            </HStack>
          </LinkBox>
        </ListItem>
      ))}
    </List>
  )
}

export default TeamList
