import { Box, Heading, Link, Text } from '@chakra-ui/react'
import React from 'react'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'

const PrivacyPage = () => {
  return (
    <>
      <Box mt={2} mb={2}>
        <MainHeading title="プライバシーポリシー" />
        <Box p={4} pt={2} pb={2}>
          <Text fontSize="xs" pb={2}>
            最終更新日: 2021年7月23日
          </Text>
          <Text fontSize="xs">
            本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における、利用ユーザーの皆さま（以下、「ユーザー」といいます。）の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            1. 個人情報
          </Heading>
          <Text fontSize="xs">
            「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名,
            生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            2. 個人情報の収集
          </Heading>
          <Text fontSize="xs">
            本サービスでは、お問い合わせの際に個人情報を入力いただく場合がございます。取得した個人情報は、お問い合わせに回答する際に利用させていただくものであり、それ以外の目的では利用いたしません。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            3. アクセス解析ツール
          </Heading>
          <Text fontSize="xs">
            本サービスでは、Google LLCによるアクセス解析ツール「Google Analytics」を使用しています。Google
            Analyticsはデータ収集のためにCookieを使用していますが、このデータは匿名で収集されており、個人を特定するものではありません。Google
            Analyticsのオプトアウトは
            <Link color="teal" href="https://tools.google.com/dlpage/gaoptout?hl=ja" isExternal>
              こちら
            </Link>
            から行うことが可能です。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            4. プライバシーポリシーの変更
          </Heading>
          <Text fontSize="xs">
            本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いてユーザーに通知することなく、変更することができるものとします。また、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
          </Text>
        </Box>
      </Box>
      <Space />
      {(window.prerenderReady = true)}
    </>
  )
}

export default PrivacyPage
