import { Box } from '@chakra-ui/react'
import React from 'react'
import Helmet from 'react-helmet'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'
import NewTeamLink from '../components/new_team_link'
import Order from '../components/order'
import SearchForm from '../components/search_form'

const OrderListPage = () => {
  return (
    <>
      <Helmet>
        <title>最新の打線一覧 - 打線組</title>
        <meta property="og:title" content="最新の打線一覧 - 打線組" data-react-helmet="true" />
        <meta property="og:url" content="https://dasengumi.com/orders" data-react-helmet="true" />
        <meta property="og:type" content="article" data-react-helmet="true" />
      </Helmet>
      {(window.prerenderReady = true)}
      {/* 新規作成&検索 */}
      <Box mt={2} mb={2}>
        <MainHeading title={'自分だけの打線を組もう！'} />
        <NewTeamLink />
        <SearchForm />
      </Box>
      <Space />
      {/* 打線一覧 */}
      <Box mt={2} mb={2}>
        <MainHeading title={'打線一覧'} />
        <Order count={200} />
      </Box>
      <Space />
    </>
  )
}

export default OrderListPage
