import { Box } from '@chakra-ui/react'
import React from 'react'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'
import MoreButton from '../components/more_button'
import NewTeamLink from '../components/new_team_link'
import Order from '../components/order'
import RecommendOrder from '../components/recommend_order'
import SearchForm from '../components/search_form'
import Team from '../components/team'

const HomePage = () => {
  return (
    <>
      {/* 新規作成&検索 */}
      <Box mt={2} mb={2}>
        <MainHeading title={'自分だけの打線を組もう！'} />
        <NewTeamLink />
        <SearchForm />
      </Box>
      <Space />
      {/* おすすめ */}
      <Box mt={2} mb={2}>
        <MainHeading title={'おすすめの打線'} />
        <RecommendOrder count={5} />
      </Box>
      <Space />
      {/* チーム一覧 */}
      <Box mt={2} mb={2}>
        <MainHeading title={'最新のチーム'} />
        <Team count={5} />
        <MoreButton to={'/teams'} text={'チームをもっとみる'} />
      </Box>
      <Space />
      {/* 打線一覧 */}
      <Box mt={2} mb={2}>
        <MainHeading title={'最新の打線'} />
        <Order count={10} />
        <MoreButton to={'/orders'} text={'打線をもっとみる'} />
      </Box>
      <Space />
      {(window.prerenderReady = true)}
    </>
  )
}

export default HomePage
