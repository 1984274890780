import { Heading } from '@chakra-ui/react'
import React from 'react'

type Props = {
  title: string
}

const MainHeading: React.FC<Props> = ({ title }) => {
  return (
    <Heading as="h2" size="sm" p={4} pt={2} pb={2}>
      {title}
    </Heading>
  )
}

export default MainHeading
