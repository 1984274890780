import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_URL

export interface NewOrderParams {
  team_id: number
  batters: BatterParams[]
  author_name: string
}

interface BatterParams {
  name: string
  order_position: number
  fielding_position: number
}

export const FetchOrderList = async (count: number, team_id?: number) => {
  const query = team_id ? `&team_id=${team_id}` : ''

  return axios({
    method: 'GET',
    url: `${baseUrl}/orders?count=${count}${query}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

export const FetchRecommendOrderList = async (count: number) => {
  return axios({
    method: 'GET',
    url: `${baseUrl}/orders/recommend?count=${count}
`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

export const FetchOrderDetail = async (id: number) => {
  return axios({
    method: 'GET',
    url: `${baseUrl}/orders/${id}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

export const CreateOrder = async (data: NewOrderParams) => {
  return axios.post(`${baseUrl}/orders/new`, {
    team_id: data.team_id,
    batters: data.batters,
    author_name: data.author_name,
  })
}
