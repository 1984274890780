/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Box, Button, Input, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'
import { ErrorResponse } from '../actions/api'
import { CreateTeam, NewTeamParams } from '../actions/team'
import FormError from '../components/form_error'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'

const TeamNewPage = () => {
  const [teamId, setTeamId] = useState(0)
  const [formError, setFormError] = useState<ErrorResponse | null>(null)
  const { register, handleSubmit } = useForm<NewTeamParams>()

  const onSubmit = (data: NewTeamParams) => {
    CreateTeam(data)
      .then(function (response) {
        setTeamId(response.data.team.id)
      })
      .catch(function (error) {
        window.scrollTo(0, 0)
        setFormError(error.response.data.error)
      })
  }

  return (
    <>
      <Helmet>
        <title>チーム作成 - 打線組</title>
        <meta property="og:title" content="チーム作成 - 打線組" data-react-helmet="true" />
        <meta property="og:url" content="https://dasengumi.com/teams/new" data-react-helmet="true" />
        <meta property="og:type" content="article" data-react-helmet="true" />
      </Helmet>
      {(window.prerenderReady = true)}
      {teamId > 0 && <Redirect to={`/teams/${teamId}/orders/new`} />}
      <Box mt={2} mb={2}>
        <MainHeading title={'まずはチームを作ろう！'} />
        {formError && (
          <Box p={4} pt={2} pb={2}>
            <FormError error={formError} />
          </Box>
        )}
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={4} pt={2} pb={2}>
              <FormControl id="name" isRequired>
                <FormLabel fontWeight="bolder" color="gray.600">
                  チーム名
                </FormLabel>
                <Input id="name" name="name" ref={register({ required: true })} />
                <FormHelperText>「◯◯で打線を組んだ」の◯◯に該当します</FormHelperText>
              </FormControl>
            </Box>
            <Box p={4} pt={2} pb={2}>
              <Button type="submit" isFullWidth={true} colorScheme="yellow">
                このチームで打線を組む
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
      <Space />
    </>
  )
}

export default TeamNewPage
