import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FetchRecommendOrderList } from '../actions/order'
import OrderList from '../components/order_list'
import LoadError from './load_error'
import Loading from './loading'

type Props = {
  count: number
}

const RecommendOrder: React.FC<Props> = ({ count }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setError] = useState(false)
  const [orderList, setOrderList] = useState({
    orders: [],
  })

  useEffect(() => {
    FetchRecommendOrderList(count)
      .then(function (response) {
        setOrderList(response.data)
      })
      .catch(function () {
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [count])

  return (
    <>
      <Box mb={2} mt={2}>
        {isError ? (
          <LoadError target={'打線一覧'} />
        ) : (
          <>{isLoading ? <Loading /> : <OrderList orders={orderList.orders} />}</>
        )}
      </Box>
    </>
  )
}

export default RecommendOrder
