import { Button, HStack, Input } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

const SearchForm = () => {
  const [keyword, setKeyword] = useState<string>('')
  const { register, handleSubmit } = useForm()
  const onSubmit = () => (window.location.href = `/teams?keyword=${keyword}`)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HStack justify="center" p={4} pt={2} pb={2}>
        <Input
          id="keyword"
          name="keyword"
          ref={register({ required: true })}
          onChange={(e) => {
            const value = e.target.value
            setKeyword(value)
          }}
          value={keyword}
        />
        <Button type="submit" colorScheme="yellow">
          検索
        </Button>
      </HStack>
    </form>
  )
}

export default SearchForm
