import { Box, Heading, Link, Text } from '@chakra-ui/react'
import React from 'react'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'

const AboutPage = () => {
  return (
    <>
      <Box mt={2} mb={2}>
        <MainHeading title="打線組について" />
        <Box p={4} pt={2} pb={2}>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            打線組とは？
          </Heading>
          <Text fontSize="xs">
            打線組（だせんぐみ）は野球とインターネットカルチャーが好きな
            <Link color="teal" href="https://twitter.com/itosho" isExternal>
              itosho
            </Link>
            が開発・運営するWebサービスです。 <br />
            打線組は「◯◯で打線を組んだ」を簡単に作成・共有することが出来ます。是非、皆さんが考えた最強の打線を教えてください！
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            サービス運営について
          </Heading>
          <Text fontSize="xs">
            打線組は現在β版として開発・運営しています。そのため、今後大幅に仕様や機能が変わる可能性がございます。また、個人で開発している関係上、問い合わせや不具合の対応が遅くなる場合もございますのが、何卒ご理解いただければと思います。
            <br />
            もちろん、頑張ってサービスをよくしていきます！
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            お問い合わせ
          </Heading>
          <Text fontSize="xs">
            お問い合わせ先：www.itosho[at]gmail.com <br />
            ご意見・ご要望等ございましたらご遠慮なくご連絡ください。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            更新情報
          </Heading>
          <Text fontSize="xs">2021年7月23日: サービス利用開始！</Text>
        </Box>
      </Box>
      <Space />
      {(window.prerenderReady = true)}
    </>
  )
}

export default AboutPage
