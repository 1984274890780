import { Alert, AlertDescription, Box } from '@chakra-ui/react'
import React from 'react'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'

const InternalServerErrorPage = () => {
  return (
    <>
      <Box mt={2} mb={2}>
        <MainHeading title="予期せぬエラーが発生しました" />
        <Box p={4} pt={2} pb={2}>
          <Alert status="error">
            <AlertDescription>システム上の問題が発生しています。しばらく経ってからお試しください。</AlertDescription>
          </Alert>
        </Box>
      </Box>
      <Space />
      {(window.prerenderReady = true)}
    </>
  )
}

export default InternalServerErrorPage
