import { Box, Heading, HStack, Image, LinkBox, LinkOverlay, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { Link as ReachLink } from 'react-router-dom'
import { OrderType } from '../types/order'
import { GetDisplayDatetime } from '../utils/datetime'

type Props = {
  orders: OrderType[]
  skipOrderId?: number
}

const OrderList: React.FC<Props> = ({ orders, skipOrderId }) => {
  return (
    <List>
      {orders.map((order: OrderType) => (
        <ListItem key={order.id} borderBottomWidth="1px" _first={{ borderTopWidth: '1px' }}>
          {skipOrderId != order.id && (
            <LinkBox p={4} pt={2} pb={2}>
              <HStack>
                <LinkOverlay as={ReachLink} to={'/orders/' + order.id}>
                  <Image
                    src={order.preview_image_url}
                    alt="オーダー画像"
                    fallbackSrc="/image/fallback_preview_order.png"
                  />
                </LinkOverlay>
                <Box>
                  <Heading as="h4" size="xs" pb={2}>
                    {order.team.name}で打線組んだ
                  </Heading>
                  <Text fontSize="xs">
                    {GetDisplayDatetime(order.created_at)} by {order.author_name}監督
                  </Text>
                </Box>
              </HStack>
            </LinkBox>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default OrderList
