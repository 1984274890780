import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FetchSearchTeamList, FetchTeamList } from '../actions/team'
import { TeamType } from '../types/team'
import LoadError from './load_error'
import Loading from './loading'
import TeamList from './team_list'

type Props = {
  count: number
}

const Team: React.FC<Props> = ({ count }) => {
  // 絞り込み用の検索キーワード
  const params = new URLSearchParams(useLocation().search)
  const keyword = params.get('keyword') ?? ''

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadError, setLoadError] = useState(false)
  const [teams, setTeams] = useState<TeamType[]>([])

  useEffect(() => {
    if (keyword) {
      FetchSearchTeamList(count, keyword)
        .then(function (response) {
          setTeams(response.data.teams)
        })
        .catch(function () {
          setLoadError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      FetchTeamList(count)
        .then(function (response) {
          setTeams(response.data.teams)
        })
        .catch(function () {
          setLoadError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [count, keyword])

  return (
    <Box pt={2} pb={2}>
      {isLoadError ? <LoadError target={'チーム一覧'} /> : <>{isLoading ? <Loading /> : <TeamList teams={teams} />}</>}
    </Box>
  )
}

export default Team
