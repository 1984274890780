import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_URL

export interface NewTeamParams {
  name: string
}

export const FetchTeamList = async (count: number) => {
  return axios({
    method: 'GET',
    url: `${baseUrl}/teams?count=${count}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

export const FetchSearchTeamList = async (count: number, keyword: string) => {
  return axios({
    method: 'GET',
    url: `${baseUrl}/teams/search?keyword=${keyword}&count=${count}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

export const FetchTeamDetail = async (id: number) => {
  return axios({
    method: 'GET',
    url: `${baseUrl}/teams/${id}`,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

export const CreateTeam = async (data: NewTeamParams) => {
  return axios.post(`${baseUrl}/teams/new`, {
    name: data.name,
  })
}
