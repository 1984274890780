/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Box, Button, FormControl, FormLabel, Heading, HStack, Input, Select } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useForm } from 'react-hook-form'
import { Redirect, useParams } from 'react-router-dom'
import { ErrorResponse } from '../actions/api'
import { CreateOrder, NewOrderParams } from '../actions/order'
import { FetchTeamDetail } from '../actions/team'
import FormError from '../components/form_error'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'
import Loading from '../components/loading'
import { TeamType } from '../types/team'
import InternalServerErrorPage from './internal_server_error'
import NotFoundPage from './not_found'

const OrderNewPage = () => {
  const { id } = useParams<{ id: string }>()
  const teamId = parseInt(id, 10)

  // 初期表示時
  const [isLoading, setIsLoading] = useState(true)
  const [loadErrorStatus, setLoadErrorStatus] = useState(200)
  const [team, setTeam] = useState<TeamType | null>(null)
  // 登録時
  const [orderId, setOrderId] = useState(0)
  const [formError, setFormError] = useState<ErrorResponse | null>(null)

  const { register, handleSubmit } = useForm()

  const orderPositions = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  useEffect(() => {
    FetchTeamDetail(teamId)
      .then(function (response) {
        setTeam(response.data.team)
      })
      .catch(function (error) {
        setLoadErrorStatus(error.response.status)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id])

  const onSubmit = (data: NewOrderParams) => {
    CreateOrder(data)
      .then(function (response) {
        setOrderId(response.data.order.id)
      })
      .catch(function (error) {
        window.scrollTo(0, 0)
        setFormError(error.response.data.error)
      })
  }

  return (
    <>
      {/* 登録成功時 */}
      {orderId > 0 && <Redirect to={`/orders/${orderId}`} />}
      {/* 初期表示エラー時 */}
      {loadErrorStatus >= 400 && loadErrorStatus <= 499 && <NotFoundPage />}
      {loadErrorStatus >= 500 && <InternalServerErrorPage />}
      {/* オーダー登録 */}
      {isLoading ? (
        <Box mb={2} mt={2}>
          <Loading />
        </Box>
      ) : (
        <>
          {team && (
            <>
              <Helmet>
                <title>{team.name}で打線を組む - 打線組</title>
                <meta property="og:title" content={`${team.name}で打線を組む - 打線組`} data-react-helmet="true" />
                <meta
                  property="og:url"
                  content={`https://dasengumi.com/teams/${team.id}/orders/new`}
                  data-react-helmet="true"
                />
                <meta property="og:type" content="article" data-react-helmet="true" />
              </Helmet>
              {(window.prerenderReady = true)}
              <Box mt={2} mb={2}>
                <MainHeading title={`${team.name}で打線を組む`} />
                {formError && (
                  <Box p={4} pt={2} pb={2}>
                    <FormError error={formError} />
                  </Box>
                )}
                {/* フォーム */}
                <Box>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box p={4} pt={2} pb={2}>
                      <FormControl isRequired>
                        <FormLabel fontWeight="bolder" color="gray.600">
                          オーダー
                        </FormLabel>
                        {orderPositions.map((position, index) => (
                          <HStack key={index} pb={2}>
                            <Heading size="sm" color="gray.600">
                              {position}.
                            </Heading>
                            <input
                              type="hidden"
                              name={`batters[${index}].order_position`}
                              value={position}
                              ref={register({ required: true, valueAsNumber: true })}
                            />
                            <Input
                              id={`batters[${index}].name`}
                              name={`batters[${index}].name`}
                              ref={register({ required: true })}
                            />
                            <Select
                              maxWidth="80px"
                              name={`batters[${index}].fielding_position`}
                              ref={register({ required: true, valueAsNumber: true })}
                              defaultValue={position}
                            >
                              <option value="1">投</option>
                              <option value="2">捕</option>
                              <option value="3">一</option>
                              <option value="4">二</option>
                              <option value="5">三</option>
                              <option value="6">遊</option>
                              <option value="7">左</option>
                              <option value="8">中</option>
                              <option value="9">右</option>
                            </Select>
                          </HStack>
                        ))}
                      </FormControl>
                      <FormControl id="author_name" isRequired>
                        <FormLabel fontWeight="bolder" color="gray.600">
                          監督
                        </FormLabel>
                        <Input id="author_name" name="author_name" ref={register({ required: true })} />
                      </FormControl>
                      <input
                        type="hidden"
                        name="team_id"
                        value={teamId}
                        ref={register({ required: true, valueAsNumber: true })}
                      />
                    </Box>
                    <Box p={4} pt={2} pb={2}>
                      <Button type="submit" isFullWidth={true} colorScheme="yellow">
                        このオーダーで打線を登録する
                      </Button>
                    </Box>
                  </form>
                </Box>
                {/* フォーム */}
              </Box>
            </>
          )}
        </>
      )}
      <Space />
    </>
  )
}

export default OrderNewPage
