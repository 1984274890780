import { Box, Center, Heading, HStack, Image, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import Helmet from 'react-helmet'
import { TwitterShareButton, TwitterIcon } from 'react-share'
import { BatterType } from '../types/batter'
import { OrderType } from '../types/order'
import { GetDisplayDatetime } from '../utils/datetime'
import { GetFieldingPositionName } from '../utils/position'
import MainHeading from './layout/main_heading'
import NewOrderLink from './new_order_link'

type Props = {
  order: OrderType
  batters: BatterType[]
}

const OrderDetail: React.FC<Props> = ({ order, batters }) => {
  return (
    <>
      <Helmet>
        <title>
          {order.team.name}で打線組んだ by {order.author_name} - 打線組
        </title>
        <meta
          property="og:title"
          content={`${order.team.name}で打線組んだ by ${order.author_name} - 打線組`}
          data-react-helmet="true"
        />
        <meta property="og:url" content={`https://dasengumi.com/orders/${order.id}`} data-react-helmet="true" />
        <meta property="og:image" content={order.image_url} data-react-helmet="true" />
        <meta property="og:type" content="article" data-react-helmet="true" />
      </Helmet>
      {(window.prerenderReady = true)}
      <Box mt={2} mb={2}>
        <MainHeading title={`${order.team.name}で打線組んだ`} />
        <Text p={4} pt={2} pb={2} fontSize="sm">
          この打線は{GetDisplayDatetime(order.created_at)}に{order.author_name}によって作成されました
        </Text>
        <Box p={4} pt={2} pb={2}>
          <Image src={order.image_url} alt="オーダー画像" fallbackSrc="/image/fallback_order.png" />
        </Box>
        <Center p={4} pt={2} pb={2}>
          <HStack>
            <Heading as="h4" size="xs">
              この打線をTwitterでシェアする
            </Heading>
            <TwitterShareButton
              url={`https://dasengumi.com/orders/${order.id}`}
              title={`${order.team.name}で打線組んだ by ${order.author_name} - 打線組`}
              hashtags={['打線組', order.team.name]}
            >
              <TwitterIcon size={36} round />
            </TwitterShareButton>
          </HStack>
        </Center>
        <Box pt={2} pb={2}>
          <List>
            {batters.map((batter) => (
              <ListItem key={batter.id} borderBottomWidth="1px" _first={{ borderTopWidth: '1px' }}>
                <Box p={4}>
                  <Heading as="h3" size="xs">
                    {batter.order_position}.（{GetFieldingPositionName(batter.fielding_position)}）{batter.name}
                  </Heading>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
        <NewOrderLink teamId={order.team.id} />
      </Box>
    </>
  )
}

export default OrderDetail
