import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FetchOrderList } from '../actions/order'
import OrderList from '../components/order_list'
import { OrderType } from '../types/order'
import LoadError from './load_error'
import Loading from './loading'

type Props = {
  count: number
  teamId?: number
  skipOrderId?: number
}

const Order: React.FC<Props> = ({ count, teamId, skipOrderId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadError, setLoadError] = useState(false)
  const [orders, setOrders] = useState<OrderType[]>([])

  useEffect(() => {
    FetchOrderList(count, teamId)
      .then(function (response) {
        setOrders(response.data.orders)
      })
      .catch(function () {
        setLoadError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [count, teamId, skipOrderId])

  return (
    <Box mb={2} mt={2}>
      {isLoadError ? (
        <LoadError target={'打線一覧'} />
      ) : (
        <>{isLoading ? <Loading /> : <OrderList orders={orders} skipOrderId={skipOrderId} />}</>
      )}
    </Box>
  )
}

export default Order
