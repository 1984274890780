import { Box, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useParams } from 'react-router-dom'
import { FetchTeamDetail } from '../actions/team'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'
import Loading from '../components/loading'
import NewOrderLink from '../components/new_order_link'
import Order from '../components/order'
import { TeamType } from '../types/team'
import { GetDisplayDatetime } from '../utils/datetime'
import InternalServerErrorPage from './internal_server_error'
import NotFoundPage from './not_found'

const TeamDetailPage = () => {
  const { id } = useParams<{ id: string }>()
  const teamId = parseInt(id, 10)

  const [isLoading, setIsLoading] = useState(true)
  const [errorStatus, setErrorStatus] = useState(200)
  const [team, setTeam] = useState<TeamType | null>(null)

  useEffect(() => {
    FetchTeamDetail(teamId)
      .then(function (response) {
        setTeam(response.data.team)
      })
      .catch(function (error) {
        setErrorStatus(error.response.status)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id])

  return (
    <>
      {/* チーム詳細 */}
      {errorStatus >= 400 && errorStatus <= 499 && <NotFoundPage />}
      {errorStatus >= 500 && <InternalServerErrorPage />}
      {isLoading ? (
        <Box mb={2} mt={2}>
          <Loading />
        </Box>
      ) : (
        <>
          {team && (
            <>
              <Helmet>
                <title>チーム名「{team.name}」 - 打線組</title>
                <meta property="og:title" content={`チーム名「${team.name}」 - 打線組`} />
                <meta property="og:url" content={`https://dasengumi.com/teams/${team.id}`} data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
              </Helmet>
              {(window.prerenderReady = true)}
              <Box mt={2} mb={2}>
                <MainHeading title={`チーム名「${team.name}」`} />
                <Text p={4} pt={2} pb={2} fontSize="sm">
                  このチームは{GetDisplayDatetime(team.created_at)}に作成されました
                </Text>
                <NewOrderLink teamId={teamId} />
              </Box>
              <Space />
            </>
          )}
        </>
      )}
      {team && (
        <>
          {/* 打線一覧 */}
          <Box mt={2} mb={2}>
            <MainHeading title={'このチームの打線'} />
            <Order count={10} teamId={teamId} />
          </Box>
          <Space />
        </>
      )}
    </>
  )
}

export default TeamDetailPage
