import { Button, Center } from '@chakra-ui/react'
import React from 'react'
import { Link as ReachLink } from 'react-router-dom'

type Props = {
  teamId: number
}

const NewOrderLink: React.FC<Props> = ({ teamId }) => {
  return (
    <Center p={4} pt={2} pb={2}>
      <Button as={ReachLink} to={`/teams/${teamId}/orders/new`} isFullWidth={true} colorScheme="yellow">
        このチームで打線を組む
      </Button>
    </Center>
  )
}

export default NewOrderLink
