import { Box, Text } from '@chakra-ui/react'
import React from 'react'

type Props = {
  target: string
}

const LoadError: React.FC<Props> = ({ target }) => {
  return (
    <Box borderBottomWidth="1px">
      <Text fontSize="xs" pl={4} pr={4} pb={2} color="red">
        {target}の取得に失敗しました
        <br />
        しばらく経ってから再度読み込みを行ってください
      </Text>
    </Box>
  )
}

export default LoadError
