import { Box, Center, ChakraProvider, Divider, extendTheme, Flex, Heading, Image, Link, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import Helmet from 'react-helmet'
import { BrowserRouter, Link as ReachLink, Route, Switch } from 'react-router-dom'
import './index.css'
import { useLocation } from 'react-router-dom'
import Space from './components/layout/space'
import AboutPage from './pages/about'
import HomePage from './pages/home'
import InternalServerErrorPage from './pages/internal_server_error'
import NotFoundPage from './pages/not_found'
import OrderDetailPage from './pages/order_detail'
import OrderListPage from './pages/order_list'
import OrderNewPage from './pages/order_new'
import PrivacyPage from './pages/privacy'
import TeamDetailPage from './pages/team_detail'
import TeamListPage from './pages/team_list'
import TeamNewPage from './pages/team_new'
import TermsPage from './pages/terms'

declare global {
  interface Window {
    prerenderReady: boolean
  }
}

const theme = extendTheme({
  styles: {
    global: {
      body: {
        maxW: '600px',
        m: '0px auto',
      },
    },
  },
})

ReactGA.initialize('UA-19143772-6')
ReactGA.pageview(window.location.pathname + window.location.search)

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <ScrollToTop />
      <Helmet>
        <title>打線組 - 君だけの打線でライバルに差をつけろ！</title>
        <meta property="og:title" content="打線組 - 君だけの打線でライバルに差をつけろ！" data-react-helmet="true" />
        <meta property="og:url" content="https://dasengumi.com" data-react-helmet="true" />
        <meta property="og:image" content="https://dasengumi.com/image/share_logo.png" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
      </Helmet>
      <Heading as="h1" m={2}>
        <Link href="/" _focus={{ boxShadow: 'none' }}>
          <Image src="/image/logo.png" alt="打線組" htmlWidth="150px" m="0px auto" />
        </Link>
      </Heading>
      <Space />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/teams">
          <TeamListPage />
        </Route>
        <Route exact path="/teams/new">
          <TeamNewPage />
        </Route>
        <Route exact path="/teams/:id">
          <TeamDetailPage />
        </Route>
        <Route exact path="/orders">
          <OrderListPage />
        </Route>
        <Route exact path="/teams/:id/orders/new">
          <OrderNewPage />
        </Route>
        <Route exact path="/orders/:id">
          <OrderDetailPage />
        </Route>
        <Route exact path="/about">
          <AboutPage />
        </Route>
        <Route exact path="/terms">
          <TermsPage />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPage />
        </Route>
        <Route exact path="/error">
          <InternalServerErrorPage />
        </Route>
        <Route>
          <NotFoundPage /> {/* ソフト404 */}
        </Route>
      </Switch>
      <Divider />
      <Box bg="gray" w="100%" p={4} color="white">
        <Center>
          <Flex>
            <Link as={ReachLink} to="/about" _focus={{ boxShadow: 'none' }}>
              <Text fontSize="xs">打線組について</Text>
            </Link>
            <Text fontSize="xs" pl={2} pr={2}>
              /
            </Text>
            <Link as={ReachLink} to="/terms" _focus={{ boxShadow: 'none' }}>
              <Text fontSize="xs">利用規約</Text>
            </Link>
            <Text fontSize="xs" pl={2} pr={2}>
              /
            </Text>
            <Link as={ReachLink} to="/privacy" _focus={{ boxShadow: 'none' }}>
              <Text fontSize="xs">プライバシーポリシー</Text>
            </Link>
          </Flex>
        </Center>
        <Center mt={4}>
          <Text fontSize="xs">
            ©{' '}
            <Link href="https://itosho.dev/" isExternal>
              itosho
            </Link>{' '}
            All Rights Reserved.
          </Text>
        </Center>
      </Box>
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById('root')
)
