import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FetchOrderDetail } from '../actions/order'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'
import Loading from '../components/loading'
import Order from '../components/order'
import OrderDetail from '../components/order_detail'
import { BatterType } from '../types/batter'
import { OrderType } from '../types/order'
import InternalServerErrorPage from './internal_server_error'
import NotFoundPage from './not_found'

const OrderDetailPage = () => {
  const { id } = useParams<{ id: string }>()
  const orderId = parseInt(id, 10)

  const [isLoading, setIsLoading] = useState(true)
  const [errorStatus, setErrorStatus] = useState(200)
  const [order, setOrder] = useState<OrderType | null>(null)
  const [batters, setBatters] = useState<BatterType[]>([])

  useEffect(() => {
    FetchOrderDetail(orderId)
      .then(function (response) {
        setOrder(response.data.order)
        setBatters(response.data.batters)
      })
      .catch(function (error) {
        setErrorStatus(error.response.status)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id])

  return (
    <>
      {/* 打線詳細 */}
      {errorStatus >= 400 && errorStatus <= 499 && <NotFoundPage />}
      {errorStatus >= 500 && <InternalServerErrorPage />}
      {isLoading ? (
        <Box mb={2} mt={2}>
          <Loading />
        </Box>
      ) : (
        <>
          {order && batters && (
            <>
              <OrderDetail order={order} batters={batters} />
              <Space />
            </>
          )}
        </>
      )}
      {order && (
        <>
          {/* 打線一覧 */}
          <Box mt={2} mb={2}>
            <MainHeading title={'このチームの打線'} />
            <Order count={10} teamId={order.team.id} skipOrderId={orderId} />
          </Box>
          <Space />
        </>
      )}
    </>
  )
}

export default OrderDetailPage
