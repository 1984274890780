import { Box, Heading, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { ErrorResponse } from '../actions/api'

type Props = {
  error: ErrorResponse
}

const FormError: React.FC<Props> = ({ error }) => {
  return (
    <Box p={2} bg="red.100">
      <Heading as="h4" size="xs" color="red.500">
        {error.title}
      </Heading>
      <List>
        {error.invalid_inputs.map((message, index) => (
          <ListItem key={index}>
            <Text fontSize="xs" color="red.500" pt={2}>
              ・{message}
            </Text>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default FormError
