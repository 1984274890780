import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'

const TermsPage = () => {
  return (
    <>
      <Box mt={2} mb={2}>
        <MainHeading title="利用規約" />
        <Box p={4} pt={2} pb={2}>
          <Text fontSize="xs" pb={2}>
            最終更新日: 2021年7月23日
          </Text>
          <Text fontSize="xs">
            この利用規約（以下、「本規約」といいます。）は、このウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。利用ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            1. 禁止事項
          </Heading>
          <Text fontSize="xs">ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。</Text>
          <Text fontSize="xs">1-1. 法令または公序良俗に違反する行為</Text>
          <Text fontSize="xs">1-2. 犯罪行為に関連する行為</Text>
          <Text fontSize="xs">
            1-3.
            本サービスの他のユーザー、または第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
          </Text>
          <Text fontSize="xs">1-4. 本サービスの運営を妨害するおそれのある行為</Text>
          <Text fontSize="xs">1-5. 他のユーザーに関する個人情報等を収集または蓄積する行為</Text>
          <Text fontSize="xs">1-6. 不正アクセスをし、またはこれを試みる行為</Text>
          <Text fontSize="xs">1-7. 他のユーザーに成りすます行為</Text>
          <Text fontSize="xs">1-8. 本サービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</Text>
          <Text fontSize="xs">
            1-9.
            本サービスの他のユーザーまたは第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為
          </Text>
          <Text fontSize="xs">
            1-10. 以下の表現を含み、または含むと本サービス運営者が判断する内容を本サービス上に投稿し、または送信する行為
            <br />
            ・過度に暴力的な表現
            <br />
            ・露骨な性的表現
            <br />
            ・人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現
            <br />
            ・自殺、自傷行為、薬物乱用を誘引または助長する表現
            <br />
            ・その他反社会的な内容を含み他人に不快感を与える表現
          </Text>
          <Text fontSize="xs">
            1-11. 以下を目的とし、または目的とすると本サービス運営者が判断する行為
            <br />
            ・営業、宣伝、広告、勧誘、その他営利を目的とする行為
            <br />
            ・性行為やわいせつな行為を目的とする行為
            <br />
            ・面識のない異性との出会いや交際を目的とする行為
            <br />
            ・他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為
            <br />
            ・他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為
            <br />
            ・その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
          </Text>
          <Text fontSize="xs">1-12. 宗教活動または宗教団体への勧誘行為</Text>
          <Text fontSize="xs">1-13. その他、本サービス運営者が不適切と判断する行為</Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            2. 本サービスの提供の停止等
          </Heading>
          <Text fontSize="xs">
            2-1.
            以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
            <br />
            ・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
            <br />
            ・地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
            <br />
            ・コンピュータまたは通信回線等が事故により停止した場合
            <br />
            ・その他、本サービス運営者が本サービスの提供が困難と判断した場合
          </Text>
          <Text fontSize="xs">
            2-2.
            本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            3. 著作権
          </Heading>
          <Text fontSize="xs">
            3-1.
            ユーザーは、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を得た文章、画像や映像等の情報に関してのみ、本サービスを利用し、投稿ないしアップロードすることができるものとします。
          </Text>
          <Text fontSize="xs">
            3-2.
            ユーザーが本サービスを利用して投稿ないしアップロードした文章、画像、映像等の著作権については、当該ユーザーその他既存の権利者に留保されるものとします。ただし、本サービス運営者は、本サービスを利用して投稿ないしアップロードされた文章、画像、映像等について、本サービスの改良、品質の向上、または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし、ユーザーは、この利用に関して、著作者人格権を行使しないものとします。
          </Text>
          <Text fontSize="xs">
            3-3.
            前項本文の定めるものを除き、本サービスおよび本サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて本サービス運営者がまたは本サービス運営者にその利用を許諾した権利者に帰属し、ユーザーは無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます。）、伝送、配布、出版、営業使用等をしてはならないものとします。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            4. 利用制限および登録抹消
          </Heading>
          <Text fontSize="xs">
            4-1.
            ユーザーが以下のいずれかに該当する場合には、事前の通知なく、投稿データを削除し、ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
            <br />
            ・本規約のいずれかの条項に違反した場合
            <br />
            ・登録事項に虚偽の事実があることが判明した場合
            <br />
            ・本サービス運営者からの連絡に対し、一定期間返答がない場合
            <br />
            ・本サービスについて、最終の利用から一定期間利用がない場合
            <br />
            ・その他、本サービス運営者が本サービスの利用を適当でないと判断した場合
          </Text>
          <Text fontSize="xs">
            4-2. 本条に基づき本サービス運営者が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            5. 保証の否認および免責事項
          </Heading>
          <Text fontSize="xs">
            5-1.
            本サービス運営者は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          </Text>
          <Text fontSize="xs">
            5-2.
            本サービス運営者は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する本サービス運営者とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
          </Text>
          <Text fontSize="xs">
            5-3.
            前項ただし書に定める場合であっても、本サービス運営者は、本サービス運営者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（本サービス運営者またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、本サービス運営者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
          </Text>
          <Text fontSize="xs">
            5-4.
            本サービス運営者は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            6. サービス内容の変更等
          </Heading>
          <Text fontSize="xs">
            本サービス運営者は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            7. 利用規約の変更
          </Heading>
          <Text fontSize="xs">
            本サービス運営者は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            8. 権利義務の譲渡の禁止
          </Heading>
          <Text fontSize="xs">
            ユーザーは、本サービス運営者の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
          </Text>
          <Heading as="h2" size="xs" pt={2} pb={2}>
            9. 準拠法・裁判管轄
          </Heading>
          <Text fontSize="xs">9-1. 本規約の解釈にあたっては、日本法を準拠法とします。</Text>
          <Text fontSize="xs">
            9-2.
            本サービスに関して紛争が生じた場合には、本サービス運営者所在地を管轄する裁判所を専属的合意管轄とします。
          </Text>
        </Box>
      </Box>
      <Space />
      {(window.prerenderReady = true)}
    </>
  )
}

export default TermsPage
