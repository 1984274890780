import { Button, Center } from '@chakra-ui/react'
import React from 'react'
import { Link as ReachLink } from 'react-router-dom'

type Props = {
  to: string
  text: string
}

const MoreButton: React.FC<Props> = ({ to, text }) => {
  return (
    <Center p={4} pt={2} pb={2}>
      <Button as={ReachLink} to={to} isFullWidth={true} colorScheme="teal">
        {text}
      </Button>
    </Center>
  )
}

export default MoreButton
