/* eslint-disable @typescript-eslint/no-explicit-any*/
const FieldingPositions = [
  {
    id: 1,
    label: '投',
  },
  {
    id: 2,
    label: '捕',
  },
  {
    id: 3,
    label: '一',
  },
  {
    id: 4,
    label: '二',
  },
  {
    id: 5,
    label: '三',
  },
  {
    id: 6,
    label: '遊',
  },
  {
    id: 7,
    label: '左',
  },
  {
    id: 8,
    label: '中',
  },
  {
    id: 9,
    label: '右',
  },
]

export const GetFieldingPositionName = (id: any) => {
  const position = FieldingPositions.find((positions) => positions.id === id)

  return position ? position.label : '不明'
}
