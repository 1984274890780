import { Alert, AlertDescription, Box } from '@chakra-ui/react'
import React from 'react'
import MainHeading from '../components/layout/main_heading'
import Space from '../components/layout/space'

const NotFoundPage = () => {
  return (
    <>
      <Box mt={2} mb={2}>
        <MainHeading title="不正なリクエストです" />
        <Box p={4} pt={2} pb={2}>
          <Alert status="error">
            <AlertDescription>
              このページは削除されたか、存在しないページのため表示することができません。
            </AlertDescription>
          </Alert>
        </Box>
      </Box>
      <Space />
      {(window.prerenderReady = true)}
    </>
  )
}

export default NotFoundPage
