import { Button, Center } from '@chakra-ui/react'
import React from 'react'
import { Link as ReachLink } from 'react-router-dom'

const NewTeamLink = () => {
  return (
    <Center p={4} pt={2} pb={2}>
      <Button as={ReachLink} to={'/teams/new'} isFullWidth={true} colorScheme="yellow">
        打線を組む
      </Button>
    </Center>
  )
}

export default NewTeamLink
